import React from 'react'
import styled from '@emotion/styled'

const Layout = styled.div`
  background: #f5f5f5;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .content {
    h1 {
      background: black;
      color: white;
    }
    a {
      text-decoration: none;
      border-bottom: 2px solid black;
      color: black;
    }
  }
`

const NotFound = () => (
  <Layout>
    <div className="content">
      <h1>404 NOT FOUND ...</h1>
      <p>Oops, we couldn't find this page! <a href="/">Go back to homepage</a></p>
    </div>
  </Layout>
)

export default NotFound
